import Vue from "vue";
import VueRouter from "vue-router";
// import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [{
  path: "/index.html",
  name: "Home",
  component: () => import( /* webpackChunkName: "home" */ '../views/home/Home.vue')
},
{
  path: "/projectdetails",
  name: "projectdetails",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () =>
    import( /* webpackChunkName: "about" */ "../views/project/Project-details.vue"),
},
{
  path: "/chronicledetails",
  name: "chronicledetails",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () =>
    import( /* webpackChunkName: "about" */ "../views/chronicle/Chronicle-details.vue"),
},
{
  path: "/personals",
  name: "personals",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () =>
    import( /* webpackChunkName: "about" */ "../views/personal/Personal.vue"),
},
{
  path: "/donationsuccess",
  name: "donationsuccess",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () =>
    import( /* webpackChunkName: "about" */ "../views/donationsuccess/Donationsuccess.vue"),
},
{
  path: "/team",
  name: "team",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () =>
    import( /* webpackChunkName: "about" */ "../views/team/Team.vue"),
},
{
  path: "/teamfrom",
  name: "teamfrom",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () =>
    import( /* webpackChunkName: "about" */ "../views/team/Team-from.vue"),
},
{
  path: "/paymentsuccess",
  name: "paymentsuccess",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () =>
    import( /* webpackChunkName: "about" */ "../views/payment/Payment.vue"),
},
{
  path: "/deduction",
  name: "deduction",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () =>
    import( /* webpackChunkName: "about" */ "../views/deduction/Deduction.vue"),
}
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  // base: '/personal/index.html',
  base: getAbsolutePath(),
  routes,
});

export default router;

function getAbsolutePath() {

  let path = location.pathname

  return path.substring(0, path.lastIndexOf('/') + 1)

}