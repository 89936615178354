<template>
  <div id="app">
    <div v-if="isbrowser">
      <router-view />
      <!-- <p style="font-size: 20px;text-align: center;margin-top: 20px;width: 100%;">目前基金会正在升级，敬请期待！</p> -->
    </div>
    <div v-else class="tipsbox">
      <img src="./assets/tips.png" alt="" class="tips_img" />
    </div>
  </div>
</template>

<script>
// import this.wx from "weixin-jsapi";
import { get, post } from "./request/request.js";
// import wx from "weixin-js-sdk";
export default {
  name: "App",
  data: function () {
    return {
      isbrowser: true,
    };
  },
  mounted() {
    // let browser = navigator.userAgent.toLowerCase();
    // if (browser.match(/Alipay/i) == "alipay") {
    //   // alert("支付宝");
    //   this.isbrowser = true;
    // } else if (browser.match(/MicroMessenger/i) == "micromessenger") {
    //   // alert("微信");
    //   this.isbrowser = true;
    // } else {
    //   this.isbrowser = false;
    //   // alert("其他");
    // }
    // this.isbrowser = true;

    // console.log(this.$router.path);
    // if (this.$router.path != "/") {
    //   this.$router.replace("/");
    //   console.log("app27");
    // }
    // if (this.$router.path == "/team") {
    //   console.log("app12");
    //   console.log(this.$route.query.teamcode);
    //   if (this.$route.query.teamcode) {
    //     console.log("app15");
    //   } else {
    //     console.log("app17");
    //     if (this.$router.path != "/") {
    //       this.$router.replace("/");
    //       console.log("app20");
    //     }
    //   }
    // } else {
    //   console.log("app24");
    //   if (this.$router.path != "/") {
    //     this.$router.replace("/");
    //     console.log("app27");
    //   }
    // }
    // this.share();
  },
  methods: {
    share: function () {
      let data = {
        url: "https://usc.test.yscase.com/",
      };
      post("share/info", data).then((res) => {
        console.log(res);
        console.log("哈哈哈哈");
        if (res.status == 200) {
          // wx.config(res.data);
          wx.config({
            debug: false, // true开启调试模式,pc端会console.log手机端会alert
            appId: res.data.appId, // 必填，公众号的唯一标识，填自己的！
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名，见附录1
            rawString: res.data.rawString,
            jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage"],
          });
          //分享配置
          wx.ready(function () {
            // alert("走了ready")
            //分享到朋友圈
            wx.onMenuShareTimeline({
              title: "南华大学教育基金会", // 分享时的标题
              link: "https://usc.test.yscase.com/", // 分享时的链接
              imgUrl: "https://usc.test.yscase.com/cover1.png", // 分享时的图标
              success: function () {
                // alert("分享成功");
              },
              cancel: function () {
                // alert("取消分享");
              },
            });
            //分享给朋友
            wx.onMenuShareAppMessage({
              title: "南华大学教育基金会",
              desc: "不忘师恩，投桃报李、业成反哺，关注母校发展、助力母校建设。",
              link: "https://usc.test.yscase.com/",
              imgUrl: "https://usc.test.yscase.com/cover1.png",
              success: function () {
                // alert("分享成功");
              },
              cancel: function () {
                // alert("取消分享");
              },
            });
          });
        }
      });
    },
  },

};
</script>

<style lang="less">
@import "./assets/style/viriables.less";
* {
  margin: 0;
  padding: 0;
}
body,
html {
  width: 100%;
  height: 100%;
  background: #fff;
}
#app {
  width: 100%;
  height: 100%;
  background: #fff;

  .tipsbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;

    .tips_img {
      position: absolute;
      top: (419 / @vw);
      left: 50%;
      width: (438 / @vw);
      transform: translateX(-50%);
    }
  }
}
</style>
