import axios from 'axios'

// https://usc.test.yscase.com/ 测试
// https://jyjjh.usc.edu.cn/ 正式
const instance = axios.create({
    baseURL: 'https://usc.test.yscase.com/backend/api/',
    // baseURL: 'https://jyjjh.usc.edu.cn/backend/api/',
    timeout: 30000,
})

export const get = (url, params = {}) => {
    return new Promise((resolve, reject) => {
        instance.get(url, {
            params
        }).then((response) => {
            resolve(response.data)
        }, err => {
            reject(err)
        })
    })
}

export const post = (url, data = {}) => {
    return new Promise((resolve, reject) => {
        instance.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            resolve(response.data)
        }, err => {
            reject(err)
        })
    })
}

export const patch = (url, data = {}) => {
    return new Promise((resolve, reject) => {
        instance.patch(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            resolve(response.data)
        }).catch(err => {
            reject(err)
        })
    })
}